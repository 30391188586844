@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&family=Fira+Sans:wght@100;300;400;700&display=swap");
.titlebold {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.titlereg {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.titlelite {
  font-family: "Antonio", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.fira-sans-thin {
  font-family: "Fira Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.fira-sans-light {
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.fira-sans-regular {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fira-sans-bold {
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* CSS reset */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

input {
  border: 1px solid #b0b0b0;
  padding: 3px 5px 4px;
  color: #979797;
  width: 190px;
}

address, caption, cite, code, dfn, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.94;
  background-blend-mode: multiply;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: -1rem;
  top: 0 !important;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 3rem;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -36px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 90%;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 95Vh;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1.6px 4.1px rgba(0, 0, 0, 0.157), 0px 3.6px 9.2px rgba(0, 0, 0, 0.185), 0px 6.3px 16.1px rgba(0, 0, 0, 0.195), 0px 10px 25.5px rgba(0, 0, 0, 0.198), 0px 15.5px 39.4px rgba(0, 0, 0, 0.201), 0px 24.1px 61.5px rgba(0, 0, 0, 0.209), 0px 40px 102.1px rgba(0, 0, 0, 0.232), 0px 80px 204px rgba(0, 0, 0, 0.37);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

html {
  font-size: calc(7px + 0.390625vw);
  min-height: 100vh;
  overflow-y: hidden;
}
@media (max-width: 767px) {
  html {
    overflow-y: scroll;
  }
}
@media (min-width: 768px) {
  html {
    overflow-y: scroll;
  }
}

body {
  height: 200vh;
  background: url("/images/scroll_seq/HERO_scroller_seq000.png") no-repeat;
  background-size: 104%;
  background-color: #ffffff;
  font-family: "Fira Sans", sans-serif;
  font-size: calc(15px + 0.390625vw);
}
@media (max-width: 767px) {
  body {
    background-color: #171717;
    background-size: contain;
  }
}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
}
@media (max-width: 767px) {
  canvas {
    height: 36vh;
    top: 18%;
  }
}

#about {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #171717;
  margin-top: 100vh;
  width: auto;
  padding: 16rem 5rem 8rem 5rem;
  min-height: calc(100vh - 24rem);
  color: #F1F1F1;
  font-family: "Fira Sans", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.04rem;
  line-height: 1.75rem;
  z-index: 10;
  box-shadow: 0px 0px 1.8px rgba(0, 0, 0, 0.047), 0px 0px 5.1px rgba(0, 0, 0, 0.081), 0px 0px 10.1px rgba(0, 0, 0, 0.092), 0px 0px 17px rgba(0, 0, 0, 0.077), 0px 0px 26.4px rgba(0, 0, 0, 0.08), 0px 0px 39.2px rgba(0, 0, 0, 0.113), 0px 0px 57.7px rgba(0, 0, 0, 0.162), 0px 0px 93px rgba(0, 0, 0, 0.22);
}
#about p {
  margin-bottom: 2rem;
  text-align: justify;
}
@media (max-width: 767px) {
  #about {
    flex-direction: column;
    margin-top: 66vh;
  }
}
@media (min-width: 768px) {
  #about {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  #about {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #about {
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  #about {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  #about {
    flex-direction: row;
  }
}

.col2 {
  width: 24.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.24;
}
.col2 img {
  width: auto;
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .col2 {
    display: none;
  }
}
@media (min-width: 768px) {
  .col2 {
    display: none;
  }
}
@media (max-width: 991px) {
  .col2 {
    display: none;
  }
}
@media (min-width: 992px) {
  .col2 {
    display: none;
  }
}
@media (max-width: 1199px) {
  .col2 {
    display: none;
  }
}
@media (min-width: 1200px) {
  .col2 {
    display: flex;
  }
}

.col1 {
  width: 67%;
  flex: 0.67;
}
@media (max-width: 767px) {
  .col1 {
    flex: 1;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .col1 {
    flex: 1;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .col1 {
    flex: 1;
    width: 100%;
  }
}
@media (min-width: 992px) {
  .col1 {
    flex: 1;
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .col1 {
    flex: 1;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col1 {
    flex: 0.67;
  }
}

h3 {
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  color: #A49583;
  border-bottom: 1px solid #A49583;
  margin: 6.5rem 0 1rem 0;
  padding: 2.25rem 0;
  font-size: 2.5rem;
}

h2 {
  font-size: 8.5rem;
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  color: #A49583;
  line-height: 8rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.09rem;
}

.split {
  column-count: 2;
  gap: 3rem;
}
@media (max-width: 991px) {
  .split {
    column-count: 1;
  }
}
#I_do_this_this_down_here {
  color: #252422;
  font-size: 6rem;
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  line-height: 6.25rem;
  font-weight: 100;
  margin-bottom: 5vh;
}
#I_do_this_this_down_here li {
  text-align: right;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  height: 3rem;
  padding: 4rem 5rem;
  width: auto;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  mix-blend-mode: difference;
  color: #A49583;
  font-family: "Antonio", sans-serif;
  font-size: calc(16px + 0.390625vw);
  z-index: 15;
}
header h1 {
  text-transform: uppercase;
}
header ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
header ul li {
  list-style: none;
  padding: 0;
}
header ul li a {
  color: #A49583;
  text-decoration: none;
}
header ul li a:hover {
  mix-blend-mode: luminosity;
  color: #fff;
}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

#closer_videopopup {
  position: absolute;
  display: table-cell;
  right: 2%;
  top: 0%;
  font-size: 4em;
  font-weight: lighter;
  text-align: center;
  vertical-align: middle;
  padding: auto;
  cursor: default;
  background: none;
  border: none;
  color: rgba(255, 198, 98, 0.9);
  font-family: "Asty CF Std", sans-serif;
  z-index: 100004;
}

#opct {
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  filter: alpha(opacity=95);
  -moz-opacity: 0.95;
  opacity: 0.95;
}

#videCont {
  position: relative;
  height: 100vh;
  margin: auto;
  max-width: 85vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

#yt_video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1 !important;
}

video {
  width: 100%;
  opacity: 1 !important;
  -webkit-box-shadow: 0 -0.5em 4em 2emrgba 0, 0, 0, 1;
  -moz-box-shadow: 0 -0.5em 4em 2em rgb(0, 0, 0);
  box-shadow: 0 -0.5em 4em 2em rgb(0, 0, 0);
}

#video1 {
  cursor: pointer;
  cursor: hand;
}

#video_overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  mix-blend-mode: multiply;
  background: rgba(41, 34, 22, 0.9);
}

#video1 {
  position: fixed;
  top: 37%;
  left: 43%;
  right: 43%;
  height: 21rem;
  width: 21rem;
  opacity: 75%;
  border: 0.5rem solid #fff;
  border-radius: 50%;
  mix-blend-mode: luminosity;
  background-size: contain;
  font-size: 0;
  transition: all 0.5s ease;
  z-index: 1;
  box-shadow: 0px 3.1px 5.1px rgba(0, 0, 0, 0.034), 0px 7.5px 10.5px rgba(0, 0, 0, 0.046), 0px 13.8px 16.6px rgba(0, 0, 0, 0.054), 0px 22.5px 24px rgba(0, 0, 0, 0.06), 0px 35.1px 34.8px rgba(0, 0, 0, 0.067), 0px 54.6px 54.6px rgba(0, 0, 0, 0.076), 0px 100px 112px rgba(0, 0, 0, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='498' height='498' viewBox='0 0 498 498'%3E%3Cpath id='Subtraction_17' data-name='Subtraction 17' d='M-9010-4391a253.019,253.019,0,0,1-25.459-1.286,249.88,249.88,0,0,1-24.724-3.773,247.762,247.762,0,0,1-46.74-14.509,249,249,0,0,1-42.3-22.958,250.782,250.782,0,0,1-36.852-30.405,250.8,250.8,0,0,1-30.405-36.851,248.954,248.954,0,0,1-22.958-42.3,247.817,247.817,0,0,1-14.509-46.74,249.708,249.708,0,0,1-3.773-24.723A253.083,253.083,0,0,1-9259-4640a253.066,253.066,0,0,1,1.285-25.458,249.664,249.664,0,0,1,3.773-24.723,247.789,247.789,0,0,1,14.509-46.74,248.958,248.958,0,0,1,22.958-42.3,250.763,250.763,0,0,1,30.405-36.852,250.742,250.742,0,0,1,36.852-30.405,249.075,249.075,0,0,1,42.3-22.958,247.781,247.781,0,0,1,46.74-14.509,249.88,249.88,0,0,1,24.724-3.773A253.019,253.019,0,0,1-9010-4889a252.985,252.985,0,0,1,25.458,1.286,249.88,249.88,0,0,1,24.724,3.773,247.781,247.781,0,0,1,46.74,14.509,249.113,249.113,0,0,1,42.3,22.958,250.853,250.853,0,0,1,36.852,30.405,250.757,250.757,0,0,1,30.4,36.852,249.032,249.032,0,0,1,22.958,42.3,247.789,247.789,0,0,1,14.509,46.74,249.374,249.374,0,0,1,3.772,24.723A252.764,252.764,0,0,1-8761-4640a252.774,252.774,0,0,1-1.286,25.459,249.418,249.418,0,0,1-3.772,24.723,247.817,247.817,0,0,1-14.509,46.74,249.078,249.078,0,0,1-22.958,42.3,250.789,250.789,0,0,1-30.4,36.851,250.76,250.76,0,0,1-36.852,30.405,249.07,249.07,0,0,1-42.3,22.958,247.762,247.762,0,0,1-46.74,14.509,249.88,249.88,0,0,1-24.724,3.773A252.985,252.985,0,0,1-9010-4391Zm-60.112-367.938-.244,240.855L-8886.218-4640l-183.895-118.939Z' transform='translate(9259 4889)' fill='%23ffa474'/%3E%3C/svg%3E%0A");
}
@media (max-width: 767px) {
  #video1 {
    top: 50%;
    left: 30%;
  }
}
#video1:hover {
  mix-blend-mode: exclusion;
}

.social-wrp {
  position: sticky;
  top: 95vh;
  height: 4rem;
  z-index: 15;
  padding: 0 5rem;
}

#my-socials {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  padding: 0;
  mix-blend-mode: difference;
  color: #A49583;
}
#my-socials li {
  height: 1.25em;
  width: 2em;
  display: block;
  margin-top: -1em;
}
#my-socials li a {
  height: 100%;
  width: 100%;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
  background-position: center;
}
#my-socials .instagram a {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.181' height='31.181' viewBox='0 0 31.181 31.181'%3E%3Cpath id='Path_913' data-name='Path 913' d='M32.088,10.164a11.443,11.443,0,0,0-.724-3.785,7.643,7.643,0,0,0-1.8-2.761,7.643,7.643,0,0,0-2.761-1.8,11.443,11.443,0,0,0-3.783-.724C21.354,1.017,20.824,1,16.591,1s-4.764.017-6.427.094a11.443,11.443,0,0,0-3.785.724,7.643,7.643,0,0,0-2.763,1.8,7.643,7.643,0,0,0-1.8,2.761,11.443,11.443,0,0,0-.724,3.783C1.017,11.827,1,12.357,1,16.591s.017,4.764.094,6.427A11.443,11.443,0,0,0,1.818,26.8a7.643,7.643,0,0,0,1.8,2.761,7.641,7.641,0,0,0,2.761,1.8,11.443,11.443,0,0,0,3.783.724c1.665.076,2.195.094,6.429.094s4.764-.017,6.427-.094a11.443,11.443,0,0,0,3.785-.724A7.972,7.972,0,0,0,31.364,26.8a11.443,11.443,0,0,0,.724-3.783c.076-1.665.094-2.195.094-6.429S32.164,11.827,32.088,10.164ZM29.281,22.891a8.623,8.623,0,0,1-.537,2.893,5.162,5.162,0,0,1-2.959,2.96,8.625,8.625,0,0,1-2.895.537c-1.646.074-2.138.09-6.3.09s-4.656-.016-6.3-.09A8.625,8.625,0,0,1,7.4,28.744,4.83,4.83,0,0,1,5.6,27.578a4.83,4.83,0,0,1-1.166-1.793A8.623,8.623,0,0,1,3.9,22.891c-.074-1.646-.09-2.138-.09-6.3s.016-4.656.09-6.3A8.625,8.625,0,0,1,4.437,7.4,4.83,4.83,0,0,1,5.6,5.6,4.83,4.83,0,0,1,7.4,4.437,8.625,8.625,0,0,1,10.29,3.9c1.646-.074,2.138-.09,6.3-.09s4.656.016,6.3.09a8.625,8.625,0,0,1,2.893.537A4.83,4.83,0,0,1,27.578,5.6,4.83,4.83,0,0,1,28.744,7.4a8.625,8.625,0,0,1,.537,2.895c.074,1.646.09,2.138.09,6.3S29.356,21.247,29.281,22.891ZM16.591,8.584A8.007,8.007,0,1,0,24.6,16.591,8.007,8.007,0,0,0,16.591,8.584Zm0,13.2a5.2,5.2,0,1,1,5.2-5.2A5.2,5.2,0,0,1,16.591,21.787ZM26.783,8.269A1.871,1.871,0,1,1,24.912,6.4,1.871,1.871,0,0,1,26.783,8.269Z' transform='translate(-1 -1)' fill='%2350504e'/%3E%3C/svg%3E%0A");
}
#my-socials .behance a {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='47.399' height='29.915' viewBox='0 0 47.399 29.915'%3E%3Cpath id='Path_914' data-name='Path 914' d='M44.79,7.226H32.784v3.339H44.838ZM42.256,30.2a5.281,5.281,0,0,1-3.078.876,5.281,5.281,0,0,1-4.026-1.279,5.9,5.9,0,0,1-1.468-4.168H49.36a15.321,15.321,0,0,0-.4-4.736,12.03,12.03,0,0,0-1.894-4.1A9.875,9.875,0,0,0,43.7,13.928a10.348,10.348,0,0,0-4.736-1.066,11.556,11.556,0,0,0-4.5.876,10.68,10.68,0,0,0-3.481,2.368,10.419,10.419,0,0,0-2.25,3.6,12.787,12.787,0,0,0-.781,4.523,13.071,13.071,0,0,0,.758,4.594A10.561,10.561,0,0,0,30.8,32.446a9.472,9.472,0,0,0,3.457,2.368,12.314,12.314,0,0,0,4.594.805A11.3,11.3,0,0,0,45.1,33.961a9.969,9.969,0,0,0,3.86-5.565H43.725a3.647,3.647,0,0,1-1.468,1.8ZM34.134,20.44a4.428,4.428,0,0,1,2.368-2.7,5.4,5.4,0,0,1,2.368-.474,4.1,4.1,0,0,1,3.221,1.16,6.891,6.891,0,0,1,1.492,3.434H33.755a7.1,7.1,0,0,1,.26-1.421ZM21.607,19.3a7.246,7.246,0,0,0,3.031-2.368,6.441,6.441,0,0,0,1.018-3.741,7.767,7.767,0,0,0-.687-3.5,5.683,5.683,0,0,0-1.942-2.368A7.672,7.672,0,0,0,20.021,6.09a17.855,17.855,0,0,0-3.884-.379H2V35.5H16.445a15.51,15.51,0,0,0,3.907-.5,10.775,10.775,0,0,0,3.386-1.539,7.412,7.412,0,0,0,2.368-2.7,8.075,8.075,0,0,0,.876-3.907,8.217,8.217,0,0,0-1.35-4.736A7.1,7.1,0,0,0,21.489,19.3ZM8.559,10.8h6.133a9.874,9.874,0,0,1,1.681.142,3.884,3.884,0,0,1,1.444.521,2.486,2.486,0,0,1,.995,1.042,3.363,3.363,0,0,1,.379,1.7,3.221,3.221,0,0,1-1.113,2.723,4.736,4.736,0,0,1-2.889.829H8.559ZM20.021,28.42A3.031,3.031,0,0,1,18.955,29.6a4.736,4.736,0,0,1-1.539.616,7.885,7.885,0,0,1-1.847.189h-7.1V22.24h7.1A5.683,5.683,0,0,1,19,23.21,3.907,3.907,0,0,1,20.281,26.5,4.191,4.191,0,0,1,20.021,28.42Z' transform='translate(-2 -5.709)' fill='%2350504e'/%3E%3C/svg%3E%0A");
}
#my-socials .linkedin a {
  background-image: url("data:image/svg+xml,%3Csvg id='Group_1151' data-name='Group 1151' xmlns='http://www.w3.org/2000/svg' width='31.328' height='31.328' viewBox='0 0 31.328 31.328'%3E%3Cpath id='Path_915' data-name='Path 915' d='M0,5H7.006V26.538H0Zm26.088.253c-.074-.023-.145-.049-.223-.07q-.141-.032-.284-.055A6.279,6.279,0,0,0,24.324,5C20.24,5,17.649,7.97,16.8,9.118V5H9.79V26.538H16.8V14.79s5.294-7.374,7.529-1.958V26.538h7V12a6.986,6.986,0,0,0-5.24-6.751Z' transform='translate(0 4.79)' fill='%2350504e'/%3E%3Ccircle id='Ellipse_71' data-name='Ellipse 71' cx='3.427' cy='3.427' r='3.427' fill='%2350504e'/%3E%3C/svg%3E%0A");
}
#my-socials #email {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.365' height='29.715' viewBox='0 0 29.365 29.715'%3E%3Cpath id='Path_923' data-name='Path 923' d='M23.625,1.3a15.846,15.846,0,0,1-7.35,1.47C9.94,2.765,5.88-2.03,5.88-7.91c0-7.91,4.725-12.32,11.62-12.32,5.985,0,10.045,3.5,10.045,9.24,0,3.85-1.61,6.51-3.64,6.51-1.12,0-1.435-.525-1.05-2.275l2.03-9.31H20.51l-.49,1.68c-.35-1.12-1.54-2.03-3.675-2.03-3.955,0-7.28,4.06-7.28,9.24,0,4.025,2.135,6.09,5.11,6.09A5.375,5.375,0,0,0,18.83-3.22c.42,1.47,2,2.17,4.06,2.17,5.18,0,8.365-3.92,8.365-10.08,0-8.33-6.37-12.39-13.72-12.39C8.26-23.52,1.89-17.22,1.89-7.945,1.89.6,8.015,6.2,16.1,6.2c4.1,0,6.65-.84,8.3-1.855ZM17.185-13.09c1.75,0,2.38,1.26,1.68,4.48-.665,2.94-1.785,4.06-3.29,4.06-1.225,0-1.82-.98-1.82-2.765C13.755-10.01,15.05-13.09,17.185-13.09Z' transform='translate(-1.89 23.52)' fill='%2350504e'/%3E%3C/svg%3E%0A");
}

.about_links {
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  text-align: right !important;
}
.about_links a {
  color: #A49583;
  line-height: 4rem;
  text-align: right;
  font-weight: 700;
  text-decoration: none;
  padding: 0 1rem;
}